/* eslint-disable import/prefer-default-export */
export const TURNKEY_FILE_URL =
  "https://ombhu-dev.s3.ap-south-1.amazonaws.com/notification/252d94c3-ad1c-4649-bebc-1f1ddc025745/2023-09-08T07%3A46%3A47.885Z/Plan.pdf";
export const BASE_URL = process.env.REACT_APP_API_URL_CORE;
export const URL_DETAIL = "/detail";
export const API_PROJECT = "/PM/project";
export const API_PROJECT_TIMELINE = `${API_PROJECT}/timeline`;
export const API_ONGOING_PROJECTS = `${API_PROJECT}/getOngoingProjects`;
export const URL_PROJECT_MANAGEMENT = "/project-management";
export const URL_PROJECT_MANAGEMENT_DETAIL = `${URL_PROJECT_MANAGEMENT}${URL_DETAIL}`;
export const TASK_STATUS_ENUM = {
  not_started: "not_started",
  in_progress: "in_progress",
  completed: "completed",
  delay: "delay",
};

export const OMBHU_LOCATIONS = [
  { label: "Bangalore", value: "Bangalore" },
  // { label: "Pune", value: "Pune" },
  // { label: "Mumbai", value: "Mumbai" },
  // { label: "Delhi", value: "Delhi" },
  // { label: "Kolkata", value: "Kolkata" },
  // { label: "Chennai", value: "Chennai" },
  // { label: "Indore", value: "Indore" },
];

export const EXPERT_DOMAIN = [
  { label: "contractor", value: "contractor" },
  { label: "interior designer", value: "interior_design" },
  { label: "architect", value: "architecture" },
];
